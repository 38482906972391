// src/About.js
import React, { CSSProperties } from "react";
import TextContainer from "./TextContainer";
const About = () => {
  return (
    <TextContainer title="About US">
      <p>
        <b>Our Mission: </b> At FeelScope, we aim to provide the most accurate
        and comprehensive sentiment analysis based on social data. We believe in
        the power of data to help us understand public emotions and their
        influence on social and economic decisions.
      </p>
      <p>
        <b>Our Technology: </b> We use cutting-edge AI and machine learning
        technologies to analyze large amounts of text data. Our natural language
        processing (NLP) algorithms automatically extract emotions from social
        media content and turn them into actionable insights.
      </p>
      <p>
        <b>Our Team: </b> The FeelScope team is made up of experts in data
        analytics, social sciences, and software development. With our
        experience in sentiment analysis, we’re committed to creating solutions
        that enhance our understanding of emotional trends across different
        regions.
      </p>
      <p>
        <b>Our Vision:</b> We see sentiment analysis as a powerful tool for
        gaining deeper insights into societies and human behavior. Our ultimate
        goal is to provide valuable insights from emotional data that help
        inform smarter, more effective decision-making.
      </p>
    </TextContainer>
  );
};

export default About;
