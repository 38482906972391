import React, { CSSProperties, useState } from "react";
import "./EmojiButton.css";
import { defaultColor } from "./colors";
import { log } from "console";

interface EmojiButtonProps {
  emoji: string;
  onClick: () => void;
  label: string;
  color?: string;
  selected: boolean;
}

const EmojiButton: React.FC<EmojiButtonProps> = ({
  emoji,
  onClick,
  label,
  color = defaultColor.main,
  selected = false,
}) => {
  //   const handleClick = () => {
  //     alert("Button clicked! 🎉");
  //   };

  //   return <button onClick={handleClick}></button>;

  // const [isSelected, setIsSelected] = useState(selected);
  //   const borderBottom: string = () => {
  //     return selected ? "1px solid white" : "none";
  //   };
  const buttonStyle: React.CSSProperties = {
    backgroundColor: "transparent",
    // color: "white",
    border: "none",
    // border: "none",
    // borderBottom: selected ? "1px solid #fff" : "none",
    // marginBottom: "0.4rem",

    borderRadius: "50%",
    // padding: "0.4rem",
    // margin: "2rem",
    fontSize: "2rem",
    // fontWeight: "600",
    // outlineStyle: "solid",
    // outlineColor: "transparent",
    // boxShadow: `0 0 0 2pt ${color}`,
    // outlineWidth: "medium",
    // outlineOffset: "1px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    // padding: "1px",
    // outline: "1px",
    // outlineColor: color,
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  };

  const styles = {
    emojiBorder: {
      borderBottom: `5px solid ${color}`,
      // visibility: "visible",
      visibility:  "visible" ,
      // visibility: selected ? "visible" : "hidden",
      paddingTop: "0.2rem",
      width: "100%",
      // margin: "0px",
    } as CSSProperties,
    // active: {
    //   borderBottom: "1px solid #fff",
    //   visibility: "visible",
    // } as CSSProperties,
    // notActive: {
    //   borderBottom: "1px solid #fff",
    //   visibility: "hidden",
    // } as CSSProperties,
    emojiCart: {
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "center",
      // alignItems: "center",
      borderRadius: "50%",
      // backgroundColor: "transparent",
      padding: "0.5px",

      // paddingTop: "0.4rem",
    } as CSSProperties,
    emojiGroup: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      // borderRadius: "50%",
      backgroundColor: "transparent",
      padding: "0.5px",

      // paddingTop: "0.4rem",
    } as CSSProperties,
  };
  // console.log({ label, selected });
  return (
    <div
      // className="emoji-border.active"
      //   className={`${selected ? "emoji-border.active" : "emoji-border"}`}
      style={styles.emojiGroup}
    >
      {/* <div className={`${selected ? "emoji-border.active" : "emoji-border"}`}> */}
      <div style={styles.emojiCart}>
        <button
          onClick={onClick}
          style={buttonStyle}
          //   className={`${selected ? "emoji-border.active" : "emoji-border"}`}
          //   className={`emoji-border`}
        >
          {/* {label} */}
          {emoji}
        </button>
      </div>
      <span style={styles.emojiBorder}></span>
      {/* <span style={selected ? styles.active : styles.notActive}></span> */}
    </div>
  );
};

// Example styling
// const buttonStyle: React.CSSProperties = {
//   backgroundColor: "#007bff",
//   color: "white",
//   border: "none",
//   borderRadius: "5px",
//   padding: "10px 20px",
//   fontSize: "16px",
//   cursor: "pointer",
// };

export default EmojiButton;
// 😊
