import React from "react";
import { CustomGeographyProps } from "./interface/CustomGeographyProps";
import { Geography } from "react-simple-maps";
import Swal from "sweetalert2";
import { Country } from "./Country";

const CustomGeography: React.FC<CustomGeographyProps> = ({
  geography,
  country,
  countryId,
  countryName,
  ...rest
}) => {
  const notSupported =
    "<div class='padding-bottom-mid'><strong > Currently not supported </strong></div>";
  const showStats = (country: Country): string => {
    try {
      let res = `<div class="names-grid" style="padding-bottom: 20px;">
      ${Object.entries(country.stats)
        .filter(([key, value]) => value)
        .map(([key, value]) => `<div><strong>${key}: </strong>${value}%</div>`)
        .join("")}
    </div>`;
      // Object.entries(country.stats).forEach(([key, value]) => {
      //   res = res + `<strong>${key}: </strong>${value}% <br />`;
      // });

      return res;
    } catch (error) {
      return notSupported;
    }
  };

  const handleCountryClick = () => {
    // console.log(country, countryId, countryName); // Access your custom property here
    Swal.fire({
      title: countryName,
      html: `
        ${country ? showStats(country) : notSupported}
      `,
      icon: "info",
      showCloseButton: true,
      // confirmButtonText: "Close",
      showConfirmButton: false,
      customClass: {
        // popup: "custom-swal-popup",
        // title: "custom-swal-title",
        // content: "custom-swal-content",
        icon: "custom-swal-icon",
      },
    });
  };

  return (
    <Geography geography={geography} onClick={handleCountryClick} {...rest} />
  );
};

export default CustomGeography;
