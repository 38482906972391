// src/Contact.js
import React from "react";
import TextContainer from "./TextContainer";

const TermsOfUse = () => {
  return (
    <TextContainer title="Terms of Use">
      <p>
        <b>Introduction:</b> This document lays out the Terms of Use for
        FeelScope’s services. By accessing and using our services, you agree to
        follow all the terms and conditions in this agreement.
      </p>
      <p>
        <b>Data Collection and Analysis:</b> FeelScope uses advanced techniques
        and machine learning algorithms to collect and analyze public data from
        social media. This includes text shared by users across various
        platforms, which we automatically gather and process. We obtain this
        data through public APIs of social media sites and store it securely in
        FeelScope’s databases for sentiment analysis.
      </p>
      <p>
        <b>Privacy and Data Security:</b> We value your privacy. The data we
        collect is used only in an anonymous way and solely for aggregated
        sentiment analysis. FeelScope employs advanced encryption methods to
        safeguard user data and prevent unauthorized access. No personal
        information is used in our sentiment analysis, and we do not identify
        data at the individual level.
      </p>
      <p>
        <b>Limitation of Liability:</b> The sentiment data we provide is for
        general informational purposes and shouldn’t be taken as legal, medical,
        or financial advice. FeelScope isn’t responsible for any decisions or
        actions taken based on our analysis.
      </p>
      <p>
        <b>Modifications:</b> We reserve the right to update these Terms of Use
        at any time without prior notice. It’s your responsibility to check this
        page regularly for any changes.
      </p>
    </TextContainer>
  );
};

export default TermsOfUse;
