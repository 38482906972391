// src/Contact.js
import React, { CSSProperties } from "react";
import TextContainer from "./TextContainer";

const Contact = () => {
  return (
    <TextContainer title="Contact Us">
      <p>
        <b>Get in Touch:</b> If you have any questions, suggestions, or need
        support, feel free to reach out to us using the contact form below. The
        FeelScope support team will get back to you as soon as they can.
        <p>
          <b>Email:</b> info@feelscope.com
        </p>
        <p>
          <b>Support:</b> For technical support or questions about sentiment
          data, please contact our tech support team at support@feelscope.com.
        </p>
      </p>
    </TextContainer>
  );
};

export default Contact;
