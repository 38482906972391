import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import Container from "./Container";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Header from "./Header";
import Header2 from "./Header2";
// import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Home2 from "./Home2";
import TermsOfUse from "./TermsOfUse";
// import WorldMap from "./WorldMap";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      {/* <Container>
        <WorldMap />
      </Container> */}

      <Router>
        <div className="App">
          {/* <Header /> */}
          <Header2 />
          <Routes>
            <Route path="/" element={<Home2 />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
