import React from "react";
import styles from "./TextContainer.module.css";

interface TextContainerProps {
  children: React.ReactNode;
  title: string;
}
const TextContainer: React.FC<TextContainerProps> = ({ children, title }) => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.divider}/> */}
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default TextContainer;
