import axios from "axios";

export async function getCountries(): Promise<any | null> {
  try {
    // const response = await axios.get("http://localhost:3000/api/country");
    const response = await axios.get("/api/country");
    // console.log(response);
    if (response.status === 200 && response.data?.data.length > 0)
      return response.data.data;
    return null;
  } catch (error) {
    console.error(error);
  }
}
